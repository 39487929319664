class ProductDetails extends HTMLElement {
  constructor() {
    super()

    this.tabTriggerEls = this.querySelectorAll('[data-product-details-tab]')
    this.contentEls = this.querySelectorAll('[data-product-details-content]')

    if (this.tabTriggerEls && this.tabTriggerEls.length > 0) {
      this.tabTriggerEls.forEach(tabTrigger => this.triggerTab(tabTrigger))
    }
  }

  triggerTab (el) {
    el.addEventListener('click', () => {
      this.resetTab()
      el.classList.add('active')
      const { content } = el.dataset
      const contentEl = this.querySelector(`[data-content-${content}]`)
      if (contentEl) {
        this.resetContent()
        contentEl.classList.add('active')
      }
    })
  }

  resetTab () {
    this.tabTriggerEls.forEach(tab => {
      tab.classList.remove('active')
    })
  }

  resetContent () {
    this.contentEls.forEach(content => {
      content.classList.remove('active')
    })
  }
}

if (!window.customElements.get('product-details')) {
  window.customElements.define('product-details', ProductDetails)
}